document.addEventListener('DOMContentLoaded', () => {
    // Auto open header dropdowns on hover
    if (window.innerWidth > 1400) {
        const navCategory = document.getElementById('navbarDropdownCategory')
        const navCountry = document.getElementById('navbarDropdownCountry')
        const navYear = document.getElementById('navbarDropdownYear')
        navCategory.addEventListener('mouseover', () => {
            navCategory.click();
        })
        navCountry.addEventListener('mouseover', () => {
            navCountry.click();
        })
        navYear.addEventListener('mouseover', () => {
            navYear.click();
        })
    }
})
